import axios from "axios";
import { token } from "../services/api-services";
import EndpointUrl from "../core/apiEndpoints/endPoints";
import { removeLocalStorage } from "../core/localStorage/localStorage";
// import { removeLocalStorage } from "../core/localStorage/localStorage";
// import EndpointUrl from "../core/apiEndpoints/endPoints";
const Http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    // 'Authorization': 'Bearer '
  },
  withCredentials: true,
  responseType: "json",
});
Http.interceptors.request.use(
  (config: any) => {
    if (config.headers && token) {
      config.headers.Authorization = "Bearer " + token;
    } else {
      delete Http.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Http.interceptors.response.use(
  (response: any) => {
    // const statusCode = response.data.status || response.data.status_code
    // if (statusCode === 403 || statusCode === 401) {
    //  const slug = localStorage.getItem('slug')
    //  localStorage.clear()
    //  sessionStorage.clear()
    //  let redirectUrl = `/${slug}`
    //  if (process.env.REACT_APP_SUB_FOLDER) {
    //    redirectUrl = `/${process.env.REACT_APP_SUB_FOLDER}` + redirectUrl
    //  }
    //  window.location.href = redirectUrl
    // }
    return response;
  },
  (error: any) => {
    if (error.response.status === 401) {
      window.location.href =
        `${process.env.REACT_APP_API_URL}` +
        `${EndpointUrl.logoutApi}?token=${token}&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
      removeLocalStorage("token");
    } else if (error.response.status === 422) {
      return error.response;
    } else if (error.response.status === 403) {
      window.location.href = `${process.env.REACT_APP_REDIRECT_URI}`.concat(
        "/access-denied"
      );
    } else if (error.response.status === 500) {
      return "apierror";
      // return error.response;
      // window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/serverError`;
    } else if (error.response.status === 404) {
      return "apierror";
    }
    Promise.reject(error);
  }
);
export default Http;
