import styled from "styled-components";

export const CustomerHeaderWrapper = styled.div`
  width: 100%;
  background: #ffff;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid #d6dae2;
`;
export const HeaderFlexContainer = styled.div`
  display: flex;
`;
export const HeaderLogoWrapper = styled.div`
  padding: 8px 16px;
  height: 72px;
  width: auto;
  > img {
    width: 100%;
    height: 100%;
  }
`;
export const HeaderMenuContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;

  .menu-item-single {
    font-size: 14px;
    line-height: 20px;
    color: black !important;
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    font-weight: 600;

    &::after {
      content: "";
      width: 100%;
      height: 6px;
      border-radius: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.active {
      color: #002e6e !important;
      &::after {
        background-color: #002e6e;
      }
    }
  }

  .custom-icon {
    margin-right: 4px;
  }
`;
export const HeaderMenu = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: black !important;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 600;

  &::after {
    content: "";
    width: 100%;
    height: 6px;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover {
    color: #002e6e !important;
  }
  .menu-item-single {
    font-size: 14px;
    line-height: 20px;
    color: black !important;
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    font-weight: 600;

    &::after {
      content: "";
      width: 100%;
      height: 6px;
      border-radius: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.active {
      color: #002e6e !important;
      &::after {
        background-color: #002e6e;
      }
    }
  }
`;
export const HeaderButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  > div {
    button {
      background: #2196f3;
      padding: 0;
      span {
        color: #fff;
      }
    }
  }
  .header-button-view {
    display: flex;
    gap: 16px;
    align-items: center;
    button {
      padding: 0 10px;
    }
  }

  div:nth-child(2) > button > span > div > .down-arrow svg path {
    fill: #002e6e !important;
  }
`;
export const DropDownMenuOptionsContainer = styled.div`
  > button {
    background: transparent !important;
    font-size: 14px;
    color: black !important;
    font-weight: 600;
  }
`;
export const LinkWithIcon = styled.div`
  padding: 0;
  display: flex;

  /* display: grid;
  grid-template-columns: auto 1fr; */
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  /* color: #6d7992; */
  text-decoration: none;
  cursor: pointer;
  gap: 4px;
  img {
    gap: 8px;
  }

  /*img {
    margin-right: 8px;
  }*/
  &:hover {
    color: var(--themeBlue800);
  }
  .button-icon-text {
    color: #fff;
  }
  &.primary-button {
    background: #124eb0;
    padding: 10px 16px;
    border-radius: 6px;
    color: #fff;
    &.in-dropdown {
      padding: 2px 16px;
    }
  }
  &.primary-button:hover {
    background: rgb(29, 96, 204);
    transition-duration: 0s, 0.15s;
  }
  &.secondary-button {
    background: #fff;
    padding: 10px 16px;
    border: 2px solid #d0daec;
    border-radius: 6px;
    .button-icon-text {
      color: #124eb0;
    }
    &.secondary-button:hover {
      background: #e3f2fd !important;
      transition-duration: 0s, 0.15s;
    }
  }
`;
export const SpinnerDiv = styled.div`
  &.div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    height: 100%;
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 102px;
  flex-direction: column;
  height: 100%;
  z-index: 9999;
`;
