import InvoiceList from "../../components/Invoice-components/Invoice-list/Invoice-list";
import EndpointUrl from "../../core/apiEndpoints/endPoints";
import { PageProps } from "../../services/schema/schema";

export default function Invoices() {
  const props: PageProps = {
    pageLabel: "invoices",
    displayLabel: "Invoices",
    gridName: "Invoices",
    apiDataUrl: EndpointUrl.rmaRequest,
    pageLogo: "",
    routeLabel: "invoices",
    apiData: {
      apiUrl: "",
      params: {},
    },
  };
  return <InvoiceList {...props} />;
}
