import styled from "styled-components";

export const CmpanyOptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .cmpny_name {
    font-size: 14px;
    font-weight: 800 !important;
  }
  .account_no {
    font-size: 12px;
  }
  .cmpny_address {
    font-size: 12px;
  }
`;
