import { Fragment, useCallback, useEffect, useState } from "react";
import { PiConfirmModel, PiServerGrid2, PiSpinner, PiToast } from "pixel-kit";
import { GridReadyEvent, CellClickedEvent } from "ag-grid-community";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../../core/localStorage/localStorage";
import { token, triggerApi } from "../../../services";
import EndpointUrl from "../../../core/apiEndpoints/endPoints";
import {
  getColumnFilterData,
  getPricingStateManagement,
  setPricingstateManagement,
} from "../../../helpers/helpers";
import TableGrid from "../../tablegrid";
import {
  Header,
  TableContainer,
  TableListContainer,
} from "../../Quote-components/quote-list/quote-list.component";
import InvoiceSecondaryHeader from "../Invoice-secondary-header/invoice-secondary-header";
import { ApiResponse } from "../../../services/schema/schema";
import { SpinnerDiv } from "../../CustomerHeader/customer-header-components";

export default function InvoiceList(props: any) {
  const [columndata, setColumnData] = useState([]);
  const [toastMsg, setToastMsg] = useState("");
  const [showTost, setShowTost] = useState<boolean>(false);
  const [isTostSuccess, setIsTostSuccess] = useState<boolean>(false);
  const [downloadLoader, setDowloadLoader] = useState<boolean>(false);
  let [requestInfo, setRequestInfo]: any = useState({});
  let [gridApi, setGridApi]: any = useState();
  let [stateMaintaindata, setStateMaintaindata]: any = useState();
  let baseUrl = process.env.REACT_APP_API_URL;
  let [columnApi, setcolumnApi]: any = useState();
  let userType: any = getLocalStorage("userType");

  const gridRequestParams = useCallback(() => {
    const info = {
      body: {
        grid_name: props.gridName,
        disableAutoSize: true,
        apiSource: "portal",
        serverFilterOptions: {},
      },
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        timezoneoffset: new Date().getTimezoneOffset(),
      },
      url: `${process.env.REACT_APP_API_URL}/${EndpointUrl.getInvoices}`,
    };
    setRequestInfo({ ...info });
  }, [props.gridName]);



  useEffect(() => {
    removeLocalStorage("globalSearch");
    removeLocalStorage("requestInfo");
    removeLocalStorage("quoteData");

    (async () => {
      let modified: any = await getColumnFilterData("invoice_customer");
      setColumnData(modified.column_data);
      const stateMaintain = await getPricingStateManagement("invoice_customer");
      setStateMaintaindata(stateMaintain);
      if (stateMaintain && stateMaintain.body) {
        stateMaintain.headers.Authorization = "Bearer " + token;
        stateMaintain.body.disableAutoSize = true;
        stateMaintain.body.apiSource = "portal";
        const request = stateMaintain;
        setRequestInfo({ ...request });
      } else {
        gridRequestParams();
      }
    })();
  }, [gridRequestParams, props.pageLabel]);

  const getRowClass = (params: any) => {
    if (props.gridName !== "Admin") {
      return "agrow-cursor-pointer";
    }
  };

  const onGridReady = async (params: GridReadyEvent) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    setGridApi(gridApi);
    setcolumnApi(columnApi);
  };
  const cellClicked = (e: CellClickedEvent) => {
    onHandleDownload(e.data.id);
  };

  const onHandleDownload = (Id: any) => {
    setDowloadLoader(true);
    const apiObject = {
      payload: {},
      method: "GET",
      apiUrl: `${EndpointUrl.getInvoiceDownload}/${Id}?token=${token}&apiSource=portal`,
      headers: {},
    };
    triggerApi(apiObject)
      .then((res: ApiResponse) => {
        if (res && !res.result) {
          setDowloadLoader(false);
          setToastMsg("Downloaded Successfully");
          setIsTostSuccess(true);
          setTimeout(() => {
            window.open(
              baseUrl +
              `${EndpointUrl.getInvoiceDownload}/${Id}?token=${token}&apiSource=portal`,
              "_blank"
            );
          }, 1000);
        } else {
          setToastMsg("Download Failed");
          setTimeout(() => {
            setDowloadLoader(false);
          }, 500);
          setShowTost(true);
        }
      })
      .catch((err: string) => {
        // setShowTost(true);
        console.log(err);
      });
  };
  async function triggerEvent(e: any) {
    stateMaintaindata = await getPricingStateManagement("invoice_customer");
    setStateMaintaindata({ ...stateMaintaindata });
    if (
      e.success &&
      (e.searchValue === "" || (e.searchValue && e.searchValue.length > 2))
    ) {
      const info = {
        ...requestInfo,
      };
      info.searchkey = e.searchValue;
      requestInfo = info;
      setRequestInfo({ ...requestInfo });
      const params = {
        apiSource: "portal",
        grid_name: "invoice_customer",
        data: requestInfo,
        pageNo: gridApi ? gridApi.paginationGetCurrentPage() : 0,
        columnsStateData: columnApi ? columnApi.getColumnState() : null,
      };
      setPricingstateManagement(params);
    }
  }
  return (
    <Fragment>
      {
        <Header className={userType === "2" ? "isCustomer" : ""}>
          <InvoiceSecondaryHeader
            sendEventData={triggerEvent}
            requestInfo={requestInfo}
          />
        </Header>
      }

      <TableListContainer>
        <>
          <TableContainer className={downloadLoader ? "opacity-on-load" : ""}>
            <TableGrid>
              {
                <div className="ag-theme-alpine ag-style">
                  <>
                    <PiServerGrid2
                      getRowClass={(e: any) => getRowClass(e)}
                      columns={columndata}
                      mode="paginate"
                      paginationPageSize={25}
                      cacheBlockSize={25}
                      onGridReady={(e: GridReadyEvent) => onGridReady(e)}
                      pagination={true}
                      rowHeight={40}
                      onCellClicked={(e: any) => cellClicked(e)}
                      requestInfo={requestInfo}
                      sideBar={false}
                      overlayNoRowsTemplate={"The Invoice(s) are not available"}
                    />
                  </>
                </div>
              }

              {downloadLoader && (
                <SpinnerDiv
                  style={{
                    position: "absolute",
                    left: "50%",
                    zIndex: "999",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  className="zindex"
                >
                  <PiSpinner color="primary" size={50} libraryType="atalskit" />
                </SpinnerDiv>
              )}
            </TableGrid>

          </TableContainer>
        </>
      </TableListContainer>
      <PiConfirmModel
        className={showTost ? "show text-red" : ""}
        headerLabel={"Download"}
        message={toastMsg}
        onClose={() => {
          setShowTost(false);
        }}
        onAccept={() => {
          setShowTost(false);
        }}
      />
      <PiToast
        className={isTostSuccess ? "show" : ""}
        headerLabel={"Download"}
        message={"Downloaded Successfully"}
        onClose={async () => setIsTostSuccess(false)}
      />
    </Fragment>
  );
}
