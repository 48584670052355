import { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { LicenseManager } from "ag-grid-enterprise";
import { getLocalStorage } from "../core/localStorage/localStorage";
import { Authorization } from "../modules";
const Layout = lazy(() => import("./layout"));

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_LICENCE_KEY}` || "");

/**
 * @return {void}
 */
export default function Routes() {
  let [token, setToken] = useState("");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    token = getLocalStorage("token") as string;
    setToken(token);
  }, []);

  /**
   * @return {void}
   */

  /**
   * @return {string}
   */
  function getLandingRoute(): any {
    const userInfo = getLocalStorage("userPermission")
      ? JSON.parse(getLocalStorage("userPermission") as string)
      : null;
    if (window.location.pathname.substring(1).split("/")[0]) {
      if (window.location.pathname === "/register" && token === "") {
        return window.location.pathname;
      } else if (window.location.pathname !== "/register") {
        return window.location.pathname;
      }
    } else {
      if (userInfo && userInfo["user_type"] === "1") {
        return userInfo.default_route;
      } else if (userInfo && userInfo["user_type"] === "2") {
        return "dashboard";
      }
    }
  }
  return (
    <Router>
      <Switch>
        {(() => {
          if (
            token === "" &&
            window.location.pathname.split("/").length < 4 &&
            window.location.pathname !== "/register"
          ) {
            return (
              <Route>
                <Suspense fallback={null}>
                  <Authorization />
                </Suspense>
              </Route>
            );
          } else {
            return (
              <>
                <Redirect exact from="/" to={getLandingRoute()} />

                <Suspense fallback={null}>
                  <Layout />
                </Suspense>
              </>
            );
          }
        })()}
      </Switch>
    </Router>
  );
}
