import styled from "styled-components";
import { themeFont } from "../../../core/styles/fonts";
export const SideDrawerW60 = styled.div`
  .side-drawer {
    width: 60% !important;
  }
`;
export const TableListContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  overflow: auto;
`;
export const SideMenuContainer = styled.div`
  width: 280px;
  background: #f9fbff;
  /* padding: 16px; */
  padding-top: 16px;
  /* margin-top: 20px; */
  font-family: ${themeFont};
  .css-1n0f7c5 .menu-option .menu-item-single {
    background-color: #f9fbff;
  }
  .menu-option .menu-item-single {
    border-radius: 8px;
    margin-bottom: 4px !important;
    padding: 12px 16px;
    padding-right: 0px;
  }
  .css-1n0f7c5 .menu-option .menu-item-single.active {
    background-color: #e3eaf6;
    color: var(--themeBlue800);
  }
  .menu-option .menu-item-single.active {
    border-radius: 8px;
  }
  .menu-option .menu-item-single:hover {
    border-radius: 8px;
  }
  .left-menu {
    padding: 16px;
    padding-top: 0px;
  }
  .menu-list {
    /* padding: 0px 16px; */
    /* padding: 16px; */
    padding-top: 0px;

    color: var(--themeBlue800);
    height: 100%;
    > div > div {
      background: unset !important;
    }
  }
  @media only screen and (max-width: 1366px) {
    width: 280px;
    background: #f9fbff;
    font-family: ${themeFont};
    /* margin-top: 20px; */
    .css-1n0f7c5 .menu-option .menu-item-single {
      background-color: #f9fbff;
    }
    .css-1n0f7c5 .menu-option .menu-item-single.active {
      background-color: #e3eaf6;
      color: var(--themeBlue800);
    }
    .menu-list {
      color: var(--themeBlue800);
      height: 100%;
    }
  }
`;
export const TableContainer = styled.div`
  .ag-theme-alpine .ag-style {
    padding: 0 !important;
  }
  flex: 1;
  .ag-react-container {
    display: flex;
  }
  .ag-header-cell-text {
    color: #6d7992;
  }
  .css-p150kv-ButtonBase {
    width: 100%;
  }
  .ag-theme-alpine .ag-header {
    // border: none;
  }
  .ag-root-wrapper-body {
    // border: none;
  }
  .ag-theme-alpine .ag-pinned-left-header {
    // border: none;
  }
  .ag-header-cell {
    padding-right: 4px !important;
  }
  /*.ag-header-cell {
    display: inline-flex;
    align-items: center;
    position: absolute;
    height: 100%;
    overflow: hidden;
    transition: 0s !important;
    text-align: left !important;
    transition-timing-function: ease !important;
    padding: 5px;
  }*/
  .header-align-left {
    .ag-header-cell-label {
      justify-content: end !important;
      color: var(--logHistoryCardBgColor);
    }
  }
  .ag-header-justify-center {
    .ag-header-cell-label {
      justify-content: center !important;
      color: var(--logHistoryCardBgColor);
    }
  }
  .header-justify-start {
    .ag-header-cell-label {
      justify-content: flex-start !important;
      color: var(--logHistoryCardBgColor);
    }
  }
  .ag-theme-alpine .ag-row {
    // border: none;
    // border-bottom-style: solid;
    // border-color:#F2F7FF;
    // height: 49px !important;
  }
  .css-7bttev-ButtonBase {
    width: 100%;
  }
  .ag-cell {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    // transition: 0.3s !important;
    transition-timing-function: ease !important;
    // text-align: left !important;
    font-family: ${themeFont};
    line-height: 2;
    padding: 5px;
    border: 0px;
    // height: 32px;
  }
  .css-1h65th5-ButtonBase {
    width: 100%;
  }
  .css-152ppnk-ButtonBase {
    width: 100%;
  }
  .ag-style {
    padding: 16px;
  }
  .ag-theme-alpine .ag-paging-panel {
    // border-color: transparent !important;
  }
  // .ag-theme-alpine .ag-root-wrapper .ag-ltr .ag-layout-normal{
  //     border: none !important;
  // }
  // .ag-theme-alpine .ag-root-wrapper{
  //     border: none;
  // }
  .ag-theme-alpine {
    // height: 628px !important;
    // width: 954px !important;
    // border-color: transparent !important;
    // border: none !important;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 100px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.4);
    border-radius: 6px;
  }
  @media only screen and (min-width: 1440px) {
    flex: 1;

    .ag-header-cell-text {
      color: #6d7992;
    }
    .css-p150kv-ButtonBase {
      width: 100%;
    }
    .ag-theme-alpine .ag-header-cell {
      padding-left: 18px;
    }
    // .ag-theme-alpine .ag-header{
    //     border: none;
    // }
    // .ag-root-wrapper-body{
    //     border: none;
    // }
    // .ag-theme-alpine .ag-pinned-left-header{
    //     border: none;
    // }
    .ag-header-cell {
      display: inline-flex;
      align-items: center;
      position: absolute;
      height: 100%;
      overflow: hidden;
      transition: 0s !important;
      text-align: left !important;
      transition-timing-function: ease !important;
      padding: 5px;
    }
    /*.ag-header-cell-label {
      justify-content: left !important;
      color: #f7faff;
    }*/
    .ag-theme-alpine .ag-row {
      // border: none;
      // border-bottom-style: solid;
      // border-color:#F2F7FF;
      // height: 49px !important;
    }
    .css-7bttev-ButtonBase {
      width: 100%;
    }
    .ag-cell {
      display: inline-block;
      position: absolute;
      white-space: nowrap;
      // transition: 0.3s !important;
      transition-timing-function: ease !important;
      // text-align: left !important;
      font-family: ${themeFont};
      line-height: 2;
      padding: 5px;
      border: 0px;
      // height: 32px;
    }
    .css-1h65th5-ButtonBase {
      width: 100%;
    }
    .css-152ppnk-ButtonBase {
      width: 100%;
    }
    .ag-style {
      padding: 24px;
    }
    .ag-theme-alpine .ag-paging-panel {
      // border-color: transparent !important;
    }
    .ag-theme-alpine .ag-root-wrapper .ag-ltr .ag-layout-normal {
      // border: none !important;
    }
    .ag-theme-alpine .ag-root-wrapper {
      // border: none;
    }
    .ag-theme-alpine {
      // height: 628px !important;
      // width: 954px !important;
      // border-color: transparent !important;
      // border: none !important;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 100px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgb(0, 0, 0, 0.4);
      border-radius: 6px;
    }
  }
`;
export const Header = styled.div`
  .icon {
    display: flex;

    align-items: center;

    justify-content: center;

    flex: 1;
    margin-right: 10%;
  }
  &.isCustomer {
    border: unset !important;
  }
  .css-1u8sedw-Icon > svg {
    display: none;
    overflow: hidden;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eaeaea !important;

  .add-Icon {
    display: flex;
    gap: 16px;
  }
  .items-center {
    align-items: center;
  }
  .pi-toggle {
    > div > label {
      margin: 3px 6px;
    }
  }
  .css-7no60z-ButtonBase {
    padding: inherit;
  }
  .css-clvj82 {
    // transition: ease-in;
    height: auto;
    // padding: 5%;
    position: relative;
    width: min-content;
    top: 0px;
    line-height: 20px;
    max-width: 250px;
  }
  @media only screen and (min-width: 1440px) {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eaeaea !important;
  }
  @media only screen and (max-width: 1366px) {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eaeaea !important;
  }

  .customer-filter-dropdown {
    align-self: center;
    position: fixed;
    right: 115px;
    float: right;
    z-index: 20;
    .react-select__control {
      height: 50px !important;
      overflow-y: auto;
    }
  }
`;
export const QuoteActivityPill = styled.div`
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  margin: 4px 0px;
  text-transform: uppercase;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  float: right;
  &.open {
    background-color: #fff0d7;
    color: #a26a11;
  }
  &.pending_approval {
    background-color: #e3f2fd;
    color: var(--themeBlue900);
  }
  &.approved {
    background-color: #ccede5;
    color: #19836a;
  }
  &.rejected {
    background-color: #f8dbd9;
    color: #af1515;
  }
  &.closed {
    background-color: #f8dbd9;
    color: #af1515;
  }
  &.inprogress {
    background-color: #deebff;
    color: #0747a6;
  }
  &.success {
    background-color: #e3fcef;
    color: #006644;
  }
  &.removed {
    background-color: #ffebe6;
    color: #bf2600;
  }
  &.moved {
    background-color: #fff0b3;
    color: #172b4d;
  }
  &.requested {
    background-color: #fff0d7;
    color: #a26a11;
  }
  &.Ordered {
    background-color: #dee4f4;
    color: #11508e;
  }
  &.Partially {
    background-color: #deebff;
    color: #134c85;
  }
  &.hi {
    background: red;
  }
`;
