import { getLocalStorage } from "../core/localStorage/localStorage";

/**
 * @param {string} label required params
 *  @param {string} lang required params
 * @return {string} language
 */

/**
 * @param {string} props required params
 * @return {any} props
 */
export function saveFilter(props: any) {
  return props;
}

/**
 * @param {string} label required params
 * @return {any} a new row
 */
export async function getUserPermissions(
  label: string,
  permissionType: string
) {
  let a = await getPerData(label, permissionType);
  return a;
}

let isAllowed: any;

/**
 * @param {string} label required params
 * @return {any} a new row
 */
async function getPerData(label: string, permissionType: string) {
  let userPerm: any = await getLocalStorage("userPermission");
  userPerm = userPerm ? JSON.parse(userPerm) : null;
  var tifOptions: any = [];
  if (userPerm) {
    Object.keys(userPerm.permissions).forEach(function (key) {
      tifOptions.push(userPerm.permissions[key]);
    });
  }
  userPerm = tifOptions;
  userPerm &&
    userPerm.map((obj: any, index: number) => {
      if (obj.hasOwnProperty(label) && userPerm[index][label]) {
        isAllowed = obj[label][permissionType];
      }

      return userPerm;
    });
  return isAllowed;
}

/**
 * @param {string} label required params
 * @return {any} a new row
 */
export async function getPermissionObject(label: string) {
  //console.log(label)
  let userPerm: any = getLocalStorage("userPermission");
  userPerm = userPerm ? JSON.parse(userPerm) : null;
  let userObject = {};
  var tifOptions: any = [];

  if (userPerm?.permissions && typeof userPerm.permissions === 'object') {
    Object.keys(userPerm.permissions).forEach(function (key) {
      tifOptions.push(userPerm.permissions[key]);
    });
  }

  if (userPerm?.permissions && typeof userPerm.permissions === 'object') {
    userPerm.permissions = tifOptions;
    (await userPerm.permissions) &&
      userPerm.permissions.map((obj: any, index: number) => {
        if (obj.hasOwnProperty(label) && userPerm.permissions[index][label]) {
          userObject = userPerm.permissions[index][label];
          //console.log(userObject)
        } else {
          return {};
        }
        userObject = userPerm.permissions[index][label];
        return userObject;
      });
    return userObject;
  }

}

/**
 * @return {any} a new row
 */

/**
 * @return {any} a new row
 */
