import { Fragment, useEffect, useRef, useState } from "react";
import {
  SecondaryHeaderContainer,
  LeftContent,
  QuotesImgContainer,
  QuotesTitle,
  QuotesTitleContainer,
  QuotesTitleContent,
  RightContent,
  UserOrganization,
  CustomerQuoteSearchContainer,
} from "../../Quote-components/Quotes-secondary-header/quote-secondary-header-components";
import { PiSearch } from "pixel-kit";
import { getLocalStorage } from "../../../core/localStorage/localStorage";
import InvoiceIcon from "../../../assets/images/invoice-icon.svg";
export default function InvoiceSecondaryHeader({
  requestInfo,
  sendEventData,
}: any) {
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setSearchValue(requestInfo?.searchkey ? requestInfo.searchkey : "");
  }, [requestInfo]);
  const { current }: any = useRef({ timer: 0 });
  let userOrganization: any = getLocalStorage("userPermission");
  function valueChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    if (current.timer) clearTimeout(current.timer);
    current.timer = setTimeout(() => {
      sendEventData({ success: true, searchValue: e.target.value });
    }, 1000);
  }
  function clearSearch() {
    setSearchValue("");
    sendEventData({ success: true, searchValue: "" });
  }

  return (
    <Fragment>
      <SecondaryHeaderContainer>
        <LeftContent>
          <QuotesTitleContainer>
            <QuotesImgContainer className="invoice">
              <img src={InvoiceIcon} alt="loading" />
            </QuotesImgContainer>
            <QuotesTitleContent>
              <QuotesTitle>{"Invoices"}</QuotesTitle>
              <UserOrganization
                title={
                  JSON.parse(userOrganization).user_organization_info.label
                }
              >
                {JSON.parse(userOrganization).user_organization_info.label}
              </UserOrganization>
            </QuotesTitleContent>
          </QuotesTitleContainer>
        </LeftContent>

        <RightContent>
          <div className="add-Icon">
            <CustomerQuoteSearchContainer>
              <PiSearch
                libraryType="atalskit"
                onClear={() => clearSearch()}
                onValueChange={(e) => valueChanged(e)}
                placeholder={"Search By Invoice / Order ID"}
                value={searchValue}
                className={"quote-search"}
              />
            </CustomerQuoteSearchContainer>
          </div>
        </RightContent>
      </SecondaryHeaderContainer>
    </Fragment>
  );
}
