export function getStatusClassName(value: string) {
  switch (value) {
    case "Open": {
      return "open";
    }
    case "Pending Approval" || "Pending Approval - $10k": {
      return "pending_approval";
    }
    case "Pending Approval - $10k": {
      return "pending_approval";
    }
    case "Pending Approval - $25k": {
      return "pending_approval";
    }
    case "Pending Approval - $50k": {
      return "pending_approval";
    }
    case "Approved - $10k": {
      return "approved";
    }
    case "Approved - $25k": {
      return "approved";
    }
    case "Approved - $50k": {
      return "approved";
    }
    case "Approved": {
      return "approved";
    }
    case "Rejected": {
      return "rejected";
    }
    case "Lost": {
      return "rejected";
    }
    case "Closed": {
      return "closed";
    }
    case "Check In Pending": {
      return "inprogress";
    }
    case "checkedin": {
      return "success";
    }
    case "Confirmed": {
      return "success";
    }
    case "Checked-in": {
      return "success";
    }
    case "Won": {
      return "success";
    }
    case "Repairable": {
      return "success";
    }
    case "Non-Repairable": {
      return "removed";
    }
    case "Repairable - Outsource": {
      return "moved";
    }
    case "evaluation": {
      return "success";
    }
    case "Added to Quote": {
      return "success";
    }
    case "Partially Received": {
      return "Partially";
    }
    case "Requested": {
      return "requested ";
    }
    case "Ordered": {
      return "Ordered ";
    }
    case "Cancelled": {
      return "closed ";
    }
    case "Received and Completed": {
      return "success ";
    }
    case "In Progress": {
      return "inprogress ";
    }
    case "Pending QC": {
      return "inprogress ";
    }
    case "Active": {
      return "success ";
    }
    case "Pass": {
      return "success ";
    }
    case "Receiving": {
      return "inprogress";
    }
    default: {
      return "open";
    }
  }
}
