import { PiSpinner } from "pixel-kit";
import React from "react";
import { LoaderContainer } from "./loader-components";
const Loader = () => {
  return (
    <>
      <LoaderContainer>
        <PiSpinner color="primary" size={50} libraryType="atalskit" />
      </LoaderContainer>
    </>
  );
};

export default Loader;
