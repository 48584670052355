import { getStatusClassName } from "./getStatusClassname";
import { QuoteActivityPill } from "./gridStatus.component";

export default function QuoteStatusAppearance(props: string) {
  return (
    <QuoteActivityPill className={getStatusClassName(props)}>
      {props}
    </QuoteActivityPill>
  );
}
